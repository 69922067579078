.footer {
  font-size: 12px;
  line-height: 21px;
  color: #999;
  margin: 16px;
  z-index: 1;
}

.sdbg-footer {
  text-align: center;
}

.vaillant-footer {
  text-align: left;
}