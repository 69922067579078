.link {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 32px;
  padding: 20px 44px 20px 44px;
  margin: 16px;
  display: block;
  width: fit-content;
}

.box {
  background-color: #fff;
  margin: auto;
  padding: 0 16px 0 16px;
}

.sdbg-box {
  margin-right: 5vw;
  max-width: 40vw;
}

.vaillant-box {
  margin-left: 5vw;
  max-width: 50vw;
}

@media screen and (max-width: 800px) {
  .link {
    margin: auto;
  }

  .box {
    margin: 0;
    margin-top: auto;
    width: 100vw;
    max-width: 100vw;
    text-align: center;
  }
}