.content > * {
  font-family: Roboto, sans-serif;
}

.main {
  color: #333;

  background-image: url('../assets/sdbg-brand-image.jpg');
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: center;
  height: 90vh;
  display: flex;
}

@media screen and (max-width: 800px) {
  .main {
    height: 60vh;
    background-position: top;
  }
}