@font-face {
  font-family: 'Vaillant16';
  src: url('../assets/Vaillant16-Demi.woff2') format('woff2');
}

.content > * {
  font-family: 'Vaillant16', sans-serif;
}

.main {
  color: #333;
  background-image: url('../assets/vaillant-brand-image.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 60vh;
  display: flex;
}

@media screen and (max-width: 800px) {
  .main {
    height: 50vh;
    background-position: top;
  }
}